import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoGoogleButton = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <g clipPath="url(#clip0_1726_5713)">
      <path
        d="M29.8374 12.0367H28.6185V11.9739H15.0001V18.0265H23.5517C22.3041 21.5499 18.9517 24.0792 15.0001 24.0792C9.98626 24.0792 5.92116 20.0141 5.92116 15.0002C5.92116 9.98638 9.98626 5.92128 15.0001 5.92128C17.3145 5.92128 19.42 6.79438 21.0232 8.22053L25.3032 3.94056C22.6007 1.42191 18.9858 -0.131348 15.0001 -0.131348C6.64369 -0.131348 -0.13147 6.64382 -0.13147 15.0002C-0.13147 23.3566 6.64369 30.1318 15.0001 30.1318C23.3565 30.1318 30.1317 23.3566 30.1317 15.0002C30.1317 13.9857 30.0273 12.9953 29.8374 12.0367Z"
        fill="#FFC107"
      />
      <path
        d="M1.61328 7.95724L6.58476 11.6032C7.92996 8.27273 11.1878 5.92128 15.0002 5.92128C17.3146 5.92128 19.4201 6.79438 21.0233 8.22053L25.3033 3.94056C22.6008 1.42191 18.9858 -0.131348 15.0002 -0.131348C9.18815 -0.131348 4.14782 3.14994 1.61328 7.95724Z"
        fill="#FF3D00"
      />
      <path
        d="M14.9999 30.1318C18.9084 30.1318 22.4598 28.636 25.1449 26.2036L20.4617 22.2407C18.8914 23.4348 16.9727 24.0807 14.9999 24.0792C11.0642 24.0792 7.72241 21.5696 6.46346 18.0674L1.52905 21.8692C4.03333 26.7696 9.11905 30.1318 14.9999 30.1318Z"
        fill="#4CAF50"
      />
      <path
        d="M29.8374 12.0369H28.6185V11.9741H15.0001V18.0268H23.5517C22.9549 19.7036 21.88 21.169 20.4596 22.2417L20.4619 22.2401L25.1451 26.2031C24.8137 26.5042 30.1317 22.5662 30.1317 15.0004C30.1317 13.9859 30.0273 12.9955 29.8374 12.0369Z"
        fill="#1976D2"
      />
    </g>
    <defs>
      <clipPath id="clip0_1726_5713">
        <rect width={30} height={30} />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgIcoGoogleButton);
export default Memo;

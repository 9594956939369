import { FilledTextFieldProps, TextField } from '@mui/material';
import {
  Controller,
  FieldError,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import HelperText from 'components/@form/HelperText';

type Props = Pick<UseControllerProps, 'rules' | 'name'> &
  Omit<FilledTextFieldProps, 'variant' | 'name'>;

const FormTextField: React.FC<Props> = ({
  rules,
  name,
  label,
  helperText,
  ...rest
}) => {
  const { control } = useFormContext();
  const intl = useIntl();

  const getHelperText = (error?: FieldError) => {
    if (helperText && !error?.message) {
      return helperText;
    }

    if (error?.message) return intl.formatMessage({ id: error.message });
  };

  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <TextField
          label={label}
          fullWidth
          {...field}
          inputRef={ref}
          error={!!error}
          helperText={
            <HelperText error={!!error}>{getHelperText(error)}</HelperText>
          }
          {...rest}
        />
      )}
    />
  );
};

export default FormTextField;

import { Button, buttonClasses, ButtonProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import GoogleIcon from './GoogleIcon';

const ButtonStyled = styled(Button)<ButtonProps>(({ theme }) => ({
  border: '1px solid #E6E6E6',
  borderRadius: 25,
  padding: theme.spacing(3, 12),
  color: theme.palette.brand.darkBlue,
  textTransform: 'uppercase',
  '&:hover': {
    textDecoration: 'none',
    opacity: 0.6,
  },
  [`& .${buttonClasses.startIcon}`]: {
    position: 'absolute',
    left: 14,
  },
}));

const GoogleLoginButton = ({ ...rest }: ButtonProps) => {
  return (
    <ButtonStyled fullWidth startIcon={<GoogleIcon fontSize={30} />} {...rest}>
      <Typography
        variant="button"
        color="brand.darkBlue"
        sx={{ transform: 'translateY(1px)' }}
      >
        <FormattedMessage id="login.google.button" />
      </Typography>
    </ButtonStyled>
  );
};

export default GoogleLoginButton;

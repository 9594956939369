import { CheckboxProps } from '@mui/material';
import {
  Controller,
  FieldError,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import HelperText from 'components/@form/HelperText';
import Checkbox from 'components/@inputs/Checkbox';

type Props = Pick<UseControllerProps, 'rules' | 'name'> &
  Omit<CheckboxProps, 'onChange' | 'value'> & {
    label: React.ReactNode;
    helperText?: string;
  };

const FormCheckbox: React.FC<Props> = ({
  rules,
  name,
  label,
  helperText,
  ...rest
}) => {
  const { control } = useFormContext();
  const intl = useIntl();

  const getHelperText = (error?: FieldError) => {
    if (helperText && !error?.message) {
      return helperText;
    }

    if (error?.message) return intl.formatMessage({ id: error.message });
  };

  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const helper = getHelperText(error);
        return (
          <>
            <Checkbox
              value={value}
              onChange={onChange}
              label={label}
              size="small"
              {...rest}
            />
            {helper && <HelperText error={!!error}>{helper}</HelperText>}
          </>
        );
      }}
    />
  );
};

export default FormCheckbox;

import {
  Box,
  Checkbox as MuiCheckbox,
  CheckboxProps,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import IcoChecked from 'components/@icons/IcoChecked';
import IcoUnchecked from 'components/@icons/IcoUnchecked';

interface Props extends Omit<CheckboxProps, 'title'> {
  label?: ReactNode;
  titleId?: string;
}

const Checkbox: React.FC<Props> = ({ label, value, titleId, ...rest }) => {
  return (
    <FormControlLabel
      label={
        <Box>
          {titleId && (
            <Typography variant="h5" textTransform="uppercase" mb={1}>
              <FormattedMessage id={titleId} />
            </Typography>
          )}
          {label}
        </Box>
      }
      control={
        <MuiCheckbox
          checkedIcon={
            <IcoChecked
              fill="url(#paint0_linear_9400_74602)" // weird, but it refers to the gradient, will look for a better way later
            />
          }
          icon={<IcoUnchecked stroke="currentColor" fill="none" />}
          checked={!!value}
          value={value}
          {...rest}
        />
      }
    />
  );
};

export default Checkbox;

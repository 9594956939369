import { alpha, Box, CircularProgress } from '@mui/material';

const AuthLoadingOverlay = () => {
  return (
    <Box
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      bgcolor={alpha('#fff', 0.8)}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  );
};

export default AuthLoadingOverlay;

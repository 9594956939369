import { useEffect, useRef } from 'react';

import { inauth } from 'services/inauth';

import { useIsMounted } from './useIsMounted';

export const useInAuth = (): (() => string | undefined) => {
  const loadedScript = useRef(false);
  const isMounted = useIsMounted();

  useEffect(() => {
    if (isMounted && !loadedScript.current) {
      inauth.init();
      loadedScript.current = true;
    }
  }, [isMounted]);

  return inauth.getId;
};

import { Button, buttonClasses, ButtonProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from 'components/@common/Icon';

const ButtonStyled = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: '#1877F2',
  borderRadius: 25,
  padding: theme.spacing(3, 12),
  color: '#fff',
  textTransform: 'uppercase',
  '&:hover': {
    backgroundColor: '#1877F2',
    textDecoration: 'none',
    opacity: 0.8,
  },
  [`& .${buttonClasses.startIcon}`]: {
    position: 'absolute',
    left: 14,
  },
}));

const FacebookLoginButton = ({ ...rest }: ButtonProps) => {
  return (
    <ButtonStyled
      fullWidth
      startIcon={<Icon name="IcoFacebookButton" fill="#fff" />}
      {...rest}
    >
      <Typography
        variant="button"
        color="#fff"
        sx={{ transform: 'translateY(1px)' }}
      >
        <FormattedMessage id="login.facebook.button" />
      </Typography>
    </ButtonStyled>
  );
};

export default FacebookLoginButton;

import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoChecked = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 19 19" {...props}>
    <g clipPath="url(#clip0_9400_74602)">
      <path d="M14.527 2.2937H3.82287C2.97418 2.2937 2.2937 2.98183 2.2937 3.82287V14.527C2.2937 15.3681 2.97418 16.0562 3.82287 16.0562H14.527C15.3757 16.0562 16.0562 15.3681 16.0562 14.527V3.82287C16.0562 2.98183 15.3757 2.2937 14.527 2.2937ZM7.64578 12.9979L3.82287 9.17495L4.90093 8.09689L7.64578 10.8341L13.449 5.03091L14.527 6.11662L7.64578 12.9979Z" />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_9400_74602"
        x1={2.2937}
        y1={16.0562}
        x2={16.0562}
        y2={2.2937}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FE402A" />
        <stop offset={1} stopColor="#A101FF" />
      </linearGradient>
      <clipPath id="clip0_9400_74602">
        <rect width={18.35} height={18.35} />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgIcoChecked);
export default Memo;

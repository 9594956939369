import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoUnchecked = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 19 19" {...props}>
    <g clipPath="url(#clip0_9400_74569)">
      <path
        d="M3.82287 3.2937H14.527C14.8202 3.2937 15.0562 3.53085 15.0562 3.82287V14.527C15.0562 14.819 14.8202 15.0562 14.527 15.0562H3.82287C3.52972 15.0562 3.2937 14.8191 3.2937 14.527V3.82287C3.2937 3.53085 3.52972 3.2937 3.82287 3.2937Z"
        strokeWidth={2}
      />
    </g>
    <defs>
      <clipPath id="clip0_9400_74569">
        <rect width={18.35} height={18.35} />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgIcoUnchecked);
export default Memo;

import FacebookLogin from '@greatsumini/react-facebook-login';
import { Stack } from '@mui/material';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';

import config from 'config';

import FacebookLoginButton from './FacebookLoginButton';
import GoogleLoginButton from './GoogleLoginButton';

interface Props {
  onSocialLogin: (
    token: string,
    provider: 'google-authcode' | 'facebook',
  ) => void;
}

const GoogleLogin = ({ onLogin }: { onLogin: Props['onSocialLogin'] }) => {
  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: (tokenResponse) => {
      if (tokenResponse && tokenResponse.code) {
        onLogin(tokenResponse.code, 'google-authcode');
      }
    },
  });

  return <GoogleLoginButton onClick={() => googleLogin()} />;
};

const SocialLogin = ({ onSocialLogin }: Props) => {
  return (
    <Stack gap={4}>
      {!!config.FACEBOOK_CLIENT_ID.length && (
        <FacebookLogin
          appId={config.FACEBOOK_CLIENT_ID}
          onSuccess={(response) => {
            if (response) {
              onSocialLogin(response.accessToken, 'facebook');
            }
          }}
          render={({ onClick }) => <FacebookLoginButton onClick={onClick} />}
        />
      )}
      {!!config.GOOGLE_CLIENT_ID.length && (
        <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
          <GoogleLogin onLogin={onSocialLogin} />
        </GoogleOAuthProvider>
      )}
    </Stack>
  );
};

export default SocialLogin;

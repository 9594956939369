import { Box } from '@mui/material';
import { ReactNode } from 'react';

import Icon from 'components/@common/Icon';

interface Props {
  error?: boolean;
  children?: ReactNode;
}
const HelperText = ({ error = false, children }: Props) => {
  if (!children) {
    return null;
  }

  if (!error) {
    return <>{children}</>;
  }

  return (
    <Box display="flex" component="span" fontSize={14} color="error.main">
      <Icon
        name="IcoWarning"
        fontSize={14}
        fill="currentColor"
        sx={{ flexShrink: 0, mt: '2px', mr: '2px' }}
      />
      {children}
    </Box>
  );
};

export default HelperText;

import { useRouter } from 'next/router';
import { NextSeo, NextSeoProps } from 'next-seo';
import React from 'react';
import { useIntl } from 'react-intl';

import config from 'config';

const SEO: React.FC<NextSeoProps> = (seo) => {
  const { asPath, locale, locales } = useRouter();
  const intl = useIntl();

  const description =
    seo.description ?? intl.formatMessage({ id: 'global.seo.description' });
  const url = `${config.CLIENT_URL}${locale}${asPath}`;
  const defaultAlternatLanguages = locales?.map((l) => ({
    hrefLang: l === 'default' ? 'x-default' : l,
    href: `${config.CLIENT_URL}${l === 'default' ? 'en' : l}${asPath}`,
  }));

  return (
    <>
      <NextSeo
        description={description}
        languageAlternates={defaultAlternatLanguages}
        {...seo}
        canonical={url}
        additionalMetaTags={[
          {
            name: 'keywords',
            content: intl.formatMessage({ id: 'global.seo.keywords' }),
          },
        ]}
        openGraph={{
          title: seo.title,
          description:
            seo.description ??
            intl.formatMessage({ id: 'global.seo.description' }),
          siteName: seo.title,
          url: `${config.CLIENT_URL}${locale}${asPath}`,
          type: 'website',
          images: [
            {
              url: `${config.CLIENT_URL}opengraph-share-img.png`,
              width: 1200,
              height: 630,
            },
          ],
          ...seo.openGraph,
        }}
      />
    </>
  );
};

export default SEO;

/* eslint-disable @typescript-eslint/no-unsafe-call */
const hasWindow = typeof window !== 'undefined';

export const inauth = {
  init: () => {
    const sessionId = inauth.getSessionId();
    const transactionId = inauth.getTransactionId();

    // beacon script
    const s = document.getElementById('inauth');

    if (s && s.setAttribute) {
      s.setAttribute('dvct', '500');
      s.setAttribute('sid', sessionId);
      s.setAttribute('dvci', transactionId);
      s.setAttribute('id', 'bcn');
      s.setAttribute('dvc', 'a');

      s.onload = () => {
        inauth.registerCallbackWithInAuth();
      };
    }
  },

  getSessionId: () => {
    if (hasWindow && window.sessionStorage) {
      let inauthSession = sessionStorage.getItem('inauth-session');

      if (!inauthSession) {
        inauthSession = inauth.getUniqueId('digicel-international-session');
        sessionStorage.setItem('inauth-session', inauthSession);
      }

      return inauthSession;
    }

    return 'default-session';
  },

  getTransactionId: () => {
    return inauth.getUniqueId('digicel-international');
  },

  getUniqueId: (prefix: string) => {
    const ustr = Math.random().toString();
    const udat = new Date();
    const utid = ustr.substring(2, 7) + udat.getTime() + ustr.substring(7, 10);

    return `${prefix}-${utid}`;
  },

  // register the InAuth callback method
  registerCallbackWithInAuth: () => {
    if (
      hasWindow &&
      window.hasOwnProperty('_bcn') &&
      window._bcn.hasOwnProperty('dvc')
    ) {
      window._bcn.dvc.setSubmissionCallback(inauth.registeredCallBackMethod);
    }
  },

  // call back method that will be invoked when the transaction was successfully set to InAuth
  registeredCallBackMethod: () => {},

  getId: () => {
    if (hasWindow && window.hasOwnProperty('_bcn')) {
      const transactionId = window._bcn.hasOwnProperty('dvc')
        ? window._bcn.dvc.getTID()
        : undefined;

      window._bcn.flush();
      return transactionId as string | undefined;
    }

    return undefined;
  },
};
